.main-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: space-between; */
    /* height: 65rem; */
    /* height: 100rem; */
    /* max-height: 100rem; */
    min-height: 65rem;
    background-color: white;
    overflow-y: auto; /* Enable vertical scrolling */
}



/* Additional styling for header and footer if needed */
