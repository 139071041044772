.account-container{
    /* background-color: white;
    padding: 2rem;
    height: 50rem; */

    background-color: white;
    padding-right: 3%;
    padding-left: 3%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    /* width: "90%"; */
}

.avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding-bottom: 2rem;
    border-start-start-radius: 0.75rem;
    border-end-start-radius: 0.75rem;
}

.avatar-info {
    background-color: white;
    border-color: black;
    border-style: solid;
    border-width: 2px;
    padding-top: 3rem;
    padding-bottom: 2rem;
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}
  
.haeding-decoration {
    width: 90%;
    border-bottom-color: black; 
    border-bottom-width: 2; 
    border-bottom-style: solid; 
    margin-left: 2rem;
    margin-top: 2rem;
}

.subcategory {
    margin-top: 1rem; 
    padding-left: 2rem;
}

.top-container{
    width: 100%;
    min-height: 30rem;
    background-image: url('https://www.teachanywhere.com/ugc/images/Dubai/Dubai_wide-banner.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.sub-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.avatar-1 {
    /* width: 10rem; 
    height: 10rem; 
    border-width:0.15rem; 
    background-color: lightgray;
    position: absolute;
    margin-top: 5rem; */
}

.avatar-2 {
    width: 10rem; 
    height: 10rem; 
    border-width: 0.15rem; 
    border-color: white; 
    border-style: solid; 
    margin-bottom: 5rem; 
    margin-top: 5rem;
}

.box-style {
    width: 25%;
    height: 200px; /* Adjust height as needed */
    background-color: #ffffff; /* Background color of the box */
    border-radius: 10px; /* Border radius to round the corners */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Slight shadow effect */
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
}

.account_main_style{
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: space-between;
}

.contact_info_style { 
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: flex-end;
}

.user_details_style { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
    background-color: white;
}

@media (max-width: 425px)  {
    .haeding-decoration {
        margin-left: 0rem;
    }

    .subcategory {
        margin-top: 0rem; 
        margin-left: 0rem;
    }

    .account-container{
        background-color: white;
        padding: 0rem;
    }

    .account-container{
        padding-top: 3rem;
    }
    .box-style {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .avatar {
        border-top-left-radius: 0.75rem;
        border-top-right-radius: 0.75rem;
        border-bottom-left-radius: 0;
    }

    .avatar-info {
       border-bottom-left-radius: 0.75rem;
       border-bottom-right-radius: 0.75rem;
       border-top-right-radius: 0;
    }
    .account_main_style{
        display: grid; 
        flex-direction: column; 
        align-items: flex-start; 
        justify-content: flex-start;
    }
    .contact_info_style { 
        justify-content: flex-start;
    }
    .user_details_style { 
        display: none;
        flex-direction: column;
        margin-top: 5rem;
        /* background-color: red; */
        /* flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 3rem  */
    }
}