/* body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20vh;
    background-color: #073b4c;
  } */
  
  .birthdayCard {
    position: relative;
    width: 90%;
    height: 460px;
    cursor: pointer;
    transform-style: preserve-3d;
    transform: perspective(2500px);
    transition: 1s;
    margin-top: 1rem;
  }
  
  .birthdayCard:hover {
    transform: perspective(2500px) rotateY(180deg);
    box-shadow: inset 50px 20px 50px rgba(0, 0, 0, 0.2), 
    /* 0 10px 50px rgba(0, 0, 0, 0.5); */
  }
  
  .cardFront, .cardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  }
  
  .cardFront {
    background-color: #000;
    transform: rotateY(0deg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .cardBack {
    background-color: #fff;
    transform: rotateY(180deg);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .happy {
    font-family: 'Brush Script MT', cursive;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    /* color: #333; */
    color: #fff;
  }

  .happy1 {
    font-family: 'Brush Script MT', cursive;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }

  .happy2 {
    /* font-family: ; */
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .balloons {
    position: relative;
    width: 100%;
    height: 200px;
    margin-top: 20px;
  }
  
  .balloon {
    position: absolute;
    width: 85px;
    height: 95px;
    border-radius: 50%;
    opacity: 0.7;
  }
  
  .balloonOne {
    background-color: #ef476f;
    left: -10px;
    top: 50px;
  }
  
  .balloonTwo {
    background-color: #06d6a0;
    left: 90px;
    top: 20px;
  }
  
  .balloonThree {
    background-color: #ffd166;
    left: 190px;
    top: 50px;
  }
  
  .balloonFour {
    background-color: #118ab2;
    left: 290px;
    top: 20px;
  }
  
  .balloon:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 155px;
    background-color: #ffd166;
    top: 95px;
    left: 42px;
  }
  
  .balloon:after {
    content: "";
    position: absolute;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    top: 94px;
    left: 36px;
  }
  
  .balloonOne:after {
    border-bottom: 10px solid #ef476f;
  }
  
  .balloonTwo:after {
    border-bottom: 10px solid #06d6a0;
  }
  
  .balloonThree:after {
    border-bottom: 10px solid #ffd166;
  }
  
  .balloonFour:after {
    border-bottom: 10px solid #118ab2;
  }
  
  p {
    font-family: 'Brush Script MT', cursive;
    margin: 10px 0;
    color: #333;
    font-size: 18px;
  }
  
  .name {
    font-family: 'Tahoma', sans-serif;
    font-size: 20px;
    color: #333;
    text-align: center;
    margin-top: 10px;
  }
  