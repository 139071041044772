.FP-container {
    background-color: white;
    width: 80%;
    margin: 5rem;
    border-radius: 0.25rem; 
    padding: 1rem;
    align-self: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
  }

  .top_container { 
    height: 75vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: url('https://www.teachanywhere.com/ugc/images/Dubai/Dubai_wide-banner.jpg');
    background-size: cover;
    background-position: center; */

    /* width: 100%;
    min-height: 30rem;
    background-image: url('https://www.teachanywhere.com/ugc/images/Dubai/Dubai_wide-banner.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start; */
}

.banner {
    height: 75vh;
    width: 100%;
    background-image: url('https://www.teachanywhere.com/ugc/images/Dubai/Dubai_wide-banner.jpg');
    background-size: cover;
    background-position: center;
}

  .change-profile {
    display: flex; 
    align-items: center;
    justify-content: center;
  }

  .avatar_style { 
    width: 6rem;
    height: 6rem;   
    background-color: #eee;
    border-radius:100%
  }

  .text_field_style { 
    width: 70%;
    align-self: center;
  }

  @media (max-width: 1024px) {
    .FP-container {
      width: 80%;
      margin: 1rem;
      margin-top: 2rem;
    }
  }

  @media (max-width: 425px) {
    .change-profile {
      display: block; 
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .FP-container {
      width: 90%;
      margin: 0rem;
      margin-top: 2rem;
    }

    .avatar_style { 
      width: 4rem;
      height: 4rem;  
    }

    .text_field_style { 
      width: 100%;
      align-self: center 
    }

  }