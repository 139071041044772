.privacy-policy {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    /* font-family: Arial, sans-serif; */
  }
  
  .privacy-policy h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-policy h2 {
    color: black;
    margin-top: 20px;
    font-size: 1.75rem;
  }
  
  .privacy-policy p {
    color: black;
    line-height: 1.6;
    font-family: 'Montserrat';
    font-size: 0.85rem;
    font-weight: 400;
  }
  