/* CSS for animation */
@keyframes slideFromLeft {
  from {
      left: -100%; /* Start position outside the viewport */
  }
  to {
      left: 30%; /* End position where you want it to stop */
  }
}

/* Apply animation to elements with class animate-from-left */
.animate-from-left {
  animation: slideFromLeft 1s ease-in-out forwards; /* Animation properties */
}

.listing-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  overflow-y: auto; 
  padding-top: 5rem;
  /* padding-left: 2rem; */
}

.detail-container{
    width: 100%;
    margin-top: 1rem;
    align-items: center;
}

.slide {
  background-color: black;
}

.slide-image {
  width: 100%; 
  height: 90vh;
  object-fit: cover; 
  opacity: 0.45;
}

.modal-style{
  width: 50%;
  margin-left: 25%;
}

.listing-container {
  background-color: white;
  width: 100%;
  margin: 5rem;
  border-radius: 0.25rem; 
  padding: 1rem;
  align-self: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Set your desired shadow properties */
}

.image-item {
  margin-top: 1rem;
  /* display: flex;
  align-items: center;
  justify-content: flex-start; */
}

.upload__image-wrapper{
  width: 100%;
  align-items: center;
  justify-content: center;
}

.App {
  width: 100%;

}

.images-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.image-controller {
  width: 50%;
}

/* Style Listing Details */
.image-slider {
  /* width:80%; */
  width:100%;
  align-self:'center';
  height: 90vh;
  background-color: black;
}

.property-detail {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

/* @media (max-width: 1024px) {
  .image-slider {
    width:90%;
  }
  .slide-image {
    height: 45vh;
  }
} */


.property-details-view {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.property-details-view-2{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1.5rem;
}

.property-txt-style {
  font-size: 4rem;
  font-weight: 400;
  font-family:'Monserrat';
}

.heading-txt {
  position: absolute;
  z-index: 10; 
  top: 40%; 
  left: 6rem;
  text-align: left; 
}

.detail-txt {
  position: absolute;
  z-index: 10; 
  top: 48%; 
  text-align: left; 
  left: 6rem;
  min-width: 30%;

}

.top_lead_detail_design{
  /* width: 100%;
  min-height: 25rem;
   background-image: url('https://cdn-ijcmf.nitrocdn.com/wgZJdyCfHniZRfljxBqkJVQJnyFdTIeP/assets/images/optimized/rev-66c19d9/mypropertymanager.ae/wp-content/uploads/2023/03/homepage-hero-banner-min.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start; */
}

.property_details_1 {
  display: flex; 
  flex-direction: row; 
  align-items: center; 
  justify-content: space-between; 
  width: 100%; 
}

.property_details_2 {
  display: flex; 
  flex-direction: row; 
  align-items: flex-start; 
  justify-content: space-between; 
  background-color: white;
  width: 100%; 
}

.property_details_2_First_Grid{
  width: 20%;
}

.property_details_2_Second_Grid{
  width: 79%;
}

@media (max-width: 1024px) {
  .heading-txt {
    left: 4rem;
    top: 40%; 
  }
  
  .detail-txt {
    left: 4rem;
    top: 48%; 
    min-width: 30%;
  }
}

@media (max-width: 768px) {
  .modal-style{
    width: 70%;
    margin-left: 15%;
  }

  .listing-container {
    margin: 3rem;
  }

  .image-slider {
    width:100%;
  }
  .slide-image {
    height: 35vh;
  }

  .property-txt-style {
    font-size:2rem;
    font-weight: 400;
    font-family:'Monserrat';
  }

  .image-slider {
    height: 30vh;
  }

  .heading-txt {
    left: 2rem;
    top: 20%; 
  }
  
  .detail-txt {
    left: 3rem;
    top: 28%; 
    min-width: 50%;
  }

  .property_details_2 {
    display: grid; 
  }

  .property_details_2_First_Grid{
    width: 100%;
  }
  
  .property_details_2_Second_Grid{
    width: 100%;
  }
  
}

@media (max-width: 425px) {
  .modal-style{
    width: 100%;
    margin-left: 0%;
  }

  .listing-container {
    margin: 1rem;
  }

  .property-details-view {
    justify-content: flex-start;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .property_details_2 {
    display: grid; 
  }

  .property_details_1 {
    display: grid; 
  }


}