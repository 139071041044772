.main {
    min-height: 100vh; /* Ensure the container fills the entire viewport */
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  @media (min-width: 768px) {
    .main {
      padding: 5rem 3%; /* Adjust padding for larger screens */
    }
  }
  