.setting-container {
    background-color: white;
    width: 50%;
    margin: 5rem;
    border-radius: 0.25rem; 
    padding: 1rem;
    align-self: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
  }

  .change-profile {
    display: flex; 
    align-items: center;
    justify-content: center;
  }

  .avatar_style { 
    width: 6rem;
    height: 6rem;   
    background-color: #eee;
    border-radius:100%
  }

  .text_field_style1 { 
    width: 50%;
    align-self: center;
  }

  @media (max-width: 1024px) {
    .setting-container {
      width: 80%;
      margin: 1rem;
      margin-top: 2rem;
    }
  }

  @media (max-width: 425px) {
    .change-profile {
      display: block; 
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .setting-container {
      width: 90%;
      margin: 0rem;
      margin-top: 2rem;
    }

    .avatar_style { 
      width: 4rem;
      height: 4rem;  
    }

    .text_field_style1 { 
      width: 100%;
      align-self: center 
    }

  }