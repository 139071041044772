.listing-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white; 
    overflow-y:scroll
  }

  .upload__image-wrapper{
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  

  .App {
    width: 100%;
  }  

  .images-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .image-controller {
    width: 50%;
  }
  .file-upload-container {
    display: flex;
    align-items: center;
}

.file-name {
    margin-right: 10px;
    font-weight: bold;
    color: #333;
}
