.home_container {
    min-height: 80vh;
    background-color: white;
    padding-right: 3%;
    padding-left: 3%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    /* display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; */
    width: "90%";
    /* height: 75vh; */
    /* min-height: 75vh; */
  }

  .second_container {
    width:'30%';
  }


/* .first_container{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  width: 80%;
} */

.owner_details {
  width: 65%;
  height: 25rem;
  display: flex;
  flex-direction: row;
  /* border: 0.15px solid gray; */
  align-items: flex-start; 
  justify-content: space-between;
  border-radius: 2%;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  
}
.owner_data{
  width: 30%;
  height: 20rem;
  /* border: 0.15px solid gray; */
  border-radius: 2%;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
}

.profile_image{
  /* right: 1rem;
  top: 2rem;
  width: 60%;
  height: 100%;
  background-size: cover;
  border-radius: 2%; */

  /* position: absolute; */
  top: 2rem;
  right: 1rem;
  width: 60%;
  height: auto; /* Allow the height to adjust automatically based on the image's aspect ratio */
  max-height: 40vh; /* Limit the height to 80% of the viewport height to prevent excessive scaling */
  border-radius: 2%;
  overflow: hidden; /* Hide any overflow if the image exceeds the container */
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow for depth */
}

.first_container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  width: 100%; /* Adjusted to make it responsive */
}

.first_container > * {
  flex-grow: 1; /* Each item takes equal space */
  margin-right: 1rem; /* Add some space between the items */
}

.user_details {
  display: flex; 
  align-items: center; 
  justify-content: flex-start;
  padding: 2rem
}

@media (max-width: 768px) {
  .first_container {
    flex-direction: column; /* Change direction to column on smaller screens */
    align-items: center; /* Center align items on smaller screens */
  }

  .first_container > * {
    width: 100%; /* Make each item take full width on smaller screens */
    margin-bottom: 1rem; /* Add some space between the items */
    margin-right: 0; /* Remove right margin on smaller screens */
  }
}

@media (max-width: 425px) {
  .user_details {
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: flex-start;
    padding: 2rem
  }
}