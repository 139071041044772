.drawer-style {
    /* width: 5rem; */
}

.headerContainer { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.header_style { 
    width: 12.5rem; 
    height: 3.74rem; 
    background-size: cover;
}

@media (max-width: 768px) {
    .header_style { 
        width: 10rem; 
        height: 3.74rem; 
        background-size: cover;
    }

    .headerContainer { 
        justify-content: space-around;
        /* padding-left: 2rem;
        padding-right: 2rem; */
    }
  }