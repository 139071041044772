.ant-table-thead .ant-table-cell {
    background-color: black !important;
    color: white !important;
  }
  .icon-margin-left {
    margin-left: 10px;
}

.modalStyle {
  position: 'absolute';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  width: '50%';
  background-color: 'background.paper';
  /* // border: '2px solid #000'; */
  box-shadow: 24;
  padding: 4;
  background-color:"red";
  overflow-x:"visible";
  max-height: '80vh'; /* Set maxHeight to 80vh for responsiveness */
  overflow-y: 'auto'; /* Enable vertical scrolling */
  overflow-x: 'hidden'; /* Disable horizontal scrolling */
  background-color: 'red'
}

  